import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import logo from "./bla.png";
import moment from "moment";
// var axios = require("axios");
import axios from 'axios'
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const config = {
  // domain: "http://localhost:1980"
  // domain: "https://api2.scottsautoja.com/api/scotts/",
  domain: "https://do-api.scottsautoja.com/",
  name: "Scott's Auto BIMMER LIFESTYLE AUTOCARE",
  userInvoice: "https://scottsautoja.com/customer/invoice.php?id=",
  contact: `9 Church Hill Ave, Montego Bay<br/>
  (876) 859 1704<br/>
  www.scottsautoja.com / info@scottsautoja.com<br/>`
};

if (process.env.NODE_ENV === "development") {
  config.domain = "http://localhost:1980/";
  // config.domain = "https://do-api.scottsautoja.com/";
} else {
  config.domain = "https://do-api.scottsautoja.com/";
}
axios.defaults.timeout = 6000;
const axios_198 = axios.create({
  baseURL: config.domain,
  // baseURL: "http://192.168.50.124:1980",
  //   baseURL: "http://192.168.100.67:1980",
  // baseURL: "http://192.168.0.12:1980",
  // baseURL: "http://192.168.0.21:1980",
  //   baseURL: "http://192.168.0.23:1980",
  //   baseURL: "http://localhost:1980",
  // baseURL: "https://api.scottsautoja.com/api/scotts/",
  timeout: 90000,
  // headers: {'X-Custom-Header': 'foobar'}
});

export function getToken() {
  if (checkAuth()) {
    var token = localStorage.getItem("token");
    token = JSON.parse(token);
    // var decoded = jwt_decode(token);
    // console.log(decoded);

    return token;
  } else {
    return "";
  }
}


const loadImage = path => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
    img.src = path
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
  })
}

export async function printInvoice(data) {
  // var logo = require("./bla.png");
  // console.log(path.basename("./bla.png"))
  // const img = await loadImage("http://localhost/bla.png")
  // try {
  //   const img = await loadImage("https://tpc.googlesyndication.com/daca_images/simgad/4495457935966254003")
  //   console.log(img)
  // } catch (error) {
  //   console.error(error)
  // }

  // await logo.default;
  // console.log(logo.default)
  console.log(data)
  var h = document.height;
  var w = document.width;
  let items = "";
  //get products
  data.products.forEach(e => {
    items += ` <tr>
    
    <td class="description">${e.title}</td>
    <td class="price">${e.qty} x ${e.price?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })} <br/>
    ${(e.qty * e.price)?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}
    </td>
</tr>`
  })

  //totals

  items += ` <tr>
    
  <td class="description">Labour Total</td>
  <td class="price">${data.labour?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })} 
  </td>
</tr> <tr><tr>
    
  <td class="description">Products Total</td>
  <td class="price">${data.product?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })} 
  </td>
</tr> <tr>
    
<td class="description">Discount</td>
<td class="price">${data.discount?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })} 
</td>
</tr>

 <tr style="margin-top:20px;">
    
<td class="description">Amount Paid</td>
<td class="price" style="font-weight:600"><u>${data.payment?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}  </u>
</td>
</tr> <tr>
    
<td class="description">Total Due</td>
<td class="price" style="font-weight:600">${data.total?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}
</td>
</tr>`

  var printWindow = window.open('Receipt', '_new', 'height=' + h + ', width=' + w);
  printWindow.title = config.name;
  var content = `<style>td,
th,
tr,
table {
    border-top: 1px dashed #aaa;
    border-collapse: collapse;
    font-size:12px;
}

td.description,
th.description {
    width: 125px;
    max-width: 175px;
}

td.quantity,
th.quantity {
    width: 30px;
    max-width: 30px;
    word-break: break-all;
}

td.price,
th.price {
    width: 47px;
    max-width: 90px;
    word-break: break-all;
    text-align:right;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 155px;
    max-width: 255px;
}

img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}</style> <div>
<center>
 <img style='width:80px' src= '${logo}'/>
</center>
<p class="centered" style="font-weight:700;font-size:13px">
${config.name}<br/>
</p>
<small style="font-size:8px;">
Invoice ID#: ${data.id}<br/>
${moment().format("YYYY-MM-DD HH:mm")}<br/>
</small>

 <table style="width:100%">
    
     <tbody>
     ${items}
     </tbody>
 </table>
 <p class="centered" style="font-size:10px;">Thanks for your purchase!</p>
     <p class="centered" style="font-size:9px">
     ${config.contact}</p>
</div>`;

  try {
    await printWindow.document.write(content);
    printWindow.print();

    printWindow.close();
  } catch (error) {

  }

  // 
  // 

}


const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        console.log("Deleting... " + name)
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  // window.location.reload(true);
};



export async function versionCheck() {

  try {
    var store_build = await localStorage.getItem("build");
    var live_build = await getBuild();

    console.log(live_build, "live_build");
    console.log(store_build, "store_build");
    if (!store_build) { //first time.. no store
      console.log('first-run')
      // store_build = JSON.parse(store_build);
      localStorage.setItem("build", JSON.stringify(live_build));
      return;
    }

    store_build = JSON.parse(store_build);

    if (store_build.build !== live_build.build) {
      localStorage.setItem("build", JSON.stringify(live_build)); //update the store build
      // Swal.fire({
      //   text: "👋 Hi, this application has been updated and will reload.",
      //   icon: 'info',
      //   showCancelButton: false,
      //   confirmButtonText: 'OK',
      //   toast: true,
      //   position: 'bottom-end'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     refreshCacheAndReload();
      //   }
      // })
      refreshCacheAndReload();
    }
  } catch (error) {

  }


}

export function copyElementText(text) {
  // var text = document.getElementById(id).innerText;
  var elem = document.createElement("textarea");
  document.body.appendChild(elem);
  elem.value = text;
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
  Swal.fire({
    text: text + " copied",
    icon: 'success',
    toast: true,
    showConfirmButton:false,
    position: 'bottom-end',
    'timer': 2000,
  })

}



export function checkAuth() {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return true;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}

export function getProfile() {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return jwt;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}

export async function getServiceList() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "serviceList",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // throw new Error(error);
    return error.message;
  }
}

export async function getBookings() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "bookings",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // throw new Error(error);
    return Promise.reject(error);
    // return error.message;
  }
}

export async function postService(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.post(
      "service",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function postCustomer(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.post(
      "customer",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function saveCustomerDetail(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      "customer-detail",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function putService(data) {
  console.log(data);
  if (data.status === "complete" || data.status === "canceled") {
    // window.alert("Cannot update final requests");
    return Promise.reject({ message: "Cannot update final requests" });
  }
  // return false;
  try {
    const response = await axios_198.put(
      "service",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function putServiceItem(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      "serviceItem",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function doAPI(path, data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      path,
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    console.log(error.status, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function getPDF(u) {
  axios_198({
    method: "GET",
    url: u,
    responseType: "blob",
    headers: { Authorization: "Bearer " + getToken() },
  })
    .then((response) => {
      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data]);
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // window.open(fileURL);
      window.location.href = fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getPDF_(u) {
  axios_198({
    method: "GET",
    url: u,
    responseType: "blob",
    headers: { Authorization: "Bearer " + getToken() },
  })
    .then((response) => {
      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data]);
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // window.open(fileURL);
      window.open(fileURL, '_blank').focus();
      // window.location.href = fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function whatsappInvoice(u, id) {
  // window.open(`mailto:${email}?Content-Type=text/html&amp;subject=Invoice%20-%20${config.name}&body=Your invoice is ready, click %3Ca%20href%3D%27${u}%27%3Ehere%3C%2Fa%3E or the link below to view your invoice  %3Ca%20href%3D%27${u}%27%3E${u}%3C%2Fa%3E %0A%0AThank you.`, '_blank').focus(); 

  window.open(`https://wa.me/${id}?text=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus();
}

export function emailInvoice(u, id) {
  window.open(`mailto:${id}?subject=Invoice%20-%20${config.name}&body=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus();

  // window.open(`https://wa.me/${id}?text=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus();
}

export async function dataProvider(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data: { data },
      headers: { Authorization: "Bearer " + getToken() },
    });
    return response;
  } catch (error) {

    // const { data } = error.response;
    // console.log(error.toJSON());
    // console.log(error.request.status);
    if(error.request.status  === 403) return doLogout();

    Swal.fire({
      position: 'bottom-end',
      icon: 'warning',
      toast: true,
      text: error.message,
      showConfirmButton: false,
      timer: 4100
    });

    // if (error.response) {
    //   throw new Error(error.response?.statusText);
    // } else {
    //   throw new Error("Request timeout.");
    // }
  }
}

export async function dataProvider2(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data: data,
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error("Request timeout.");
    }
  }
}

export async function putCustomer(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      "customer",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    console.log(error.response, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function customerStatusChange(data) {
  //  console.log(data);
  //  return res.json(data)
  try {
    const response = await axios_198.put(
      "customer-status",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function getCustomers() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "customers",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
    // return error.message;
  }
}

export async function getCustomer(data) {
  // console.log(getToken());
  // console.log()
  try {
    const response = await axios_198.post(
      "customer-fetch",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error.message,"util")
    throw new Error(error);
    // return error;
  }
}

export async function searchGlobalCustomer(data) {
  // console.log(getToken());
  // console.log()
  try {
    const response = await axios_198.post(
      "customer-fetch",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error.message,"util")
    throw new Error(error);
    // return error;
  }
}

export async function searchGlobalProduct(data) {
  // console.log(getToken());
  // console.log()
  try {
    const response = await axios_198.post(
      "/v2/inventory/product-fetch",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error.message,"util")
    throw new Error(error);
    // return error;
  }
}

export async function doLogin(data) {
  try {
    const response = await axios_198.post("login", data);
    // console.log(response);
    return response;
  } catch (error) {
    // return error.message;
    throw new Error(error);
  }
}
export async function doLogout() {
  localStorage.removeItem("token");
  // if (caches) {
  //   // Service worker cache should be cleared with caches.delete()
  //   caches.keys().then((names) => {
  //     for (const name of names) {
  //       caches.delete(name);
  //     }
  //   });
  // }
  window.location = "/login";
}

export async function getBuild() {
  // return '';
  try {
    const b = await fetch("metadata.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // Work with JSON data here
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
        // Do something for an error here
      });
    return b;
  } catch (error) {
    console.log(error);
  }
}

export function getSettings() {
  return JSON.parse(sessionStorage.getItem("settings"));
}

export function isIOSDevice() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  // console.log(navigator.platform)
  // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}


export async function doRequest(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data: data,
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
  } catch (error) {
    // console.log(error, "doReq")
    // console.log(error.statusText, "doReq")
    // console.log(error.request, "reqq")
    const { data } = error.response;
    // console.log(data.message, "ress")

    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      // title: 'Your work has been saved',
      text: data.message,
      showConfirmButton: false,
      timer: 2100
    });
    // return false;
  }
}